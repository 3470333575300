import React, { useState, useEffect, useRef } from "react";
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";


export default function FlowingPics({ images }) {

  const [loading, setLoading] = useState(true);
  const counter = useRef(0);

  const imageLoaded = () => {
    counter.current += 1;
    if (counter.current >= images.length) {
      setLoading(false);
    }
  };

  useEffect(() => {
    counter.current = 0;
    setLoading(true);
  }, [images]);

  return (
    <div>
      {loading && (
        <div className="flex items-center justify-center z-50">
          <div className="rounded-2xl bg-stone-800 text-white p-12 animate-pulse">
            Loading High Quality Nature Images...
          </div>
        </div>
      )}
      <div style={{ display: loading ? "none" : "block" }}>
        <Swiper
          centeredSlides={true}
          slidesPerView={3}
          speed={4000}
          loop
          autoplay={{
             delay: 0,
             disableOnInteraction: false,
             pauseOnMouseEnter: false,
             stopOnLastSlide: false,
             waitForTransition: true
          }}
          modules={[Autoplay, Pagination]}
          pagination={{clickable: true}}s
        >
          {images.map((image, index) => (
            <SwiperSlide><div key={index} className="w-[80vh] flex">
              <img
                src={image.original}
                alt={image.thumbnail}
                className="object-cover h-full"
                onLoad={imageLoaded}
              />
            </div></SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}


import React from "react";
import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <div className="p-8 bg-stone-300 flex flex-col items-end text-2xl">
        <NavLink
          to="/imprint"
          className={({ isActive }) => (isActive ? "underline" : "")}
        >
          <div className="hover:text-slate-500">Imprint</div>
        </NavLink>
      </div>
  );
}
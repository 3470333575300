import React from "react";
import { NavLink } from "react-router-dom";
import papa from "../pics/richard_logo.png";

export default function Header() {
  return (
    <div className="h-[15vh] p-8 bg-stone-200 flex justify-between items-center justify-items-center">
      <h1 className="text-3xl p-3">
        <NavLink exact to="/">
          <div className="flex items-center hover:text-slate-500">
            
            <img className="h-[10vh] pr-5" src={papa} alt="papa"></img>
            <div classname="">
              Richard Witzko
              <br></br>
              <p className="text-orange-700 text-center font-mono">Photography</p>
            </div>
          </div>
        </NavLink>
      </h1>
      <div className="flex text-2xl gap-5 p-3">
        <NavLink
          exact
          to="/"
          className={({ isActive }) => (isActive ? "underline" : "")}
        >
          <div className="hover:text-slate-500">Home</div>
        </NavLink>

        <NavLink
          to="/portfolio"
          className={({ isActive }) => (isActive ? "underline" : "")}
        >
          <div className="hover:text-slate-500">Portfolio</div>
        </NavLink>
        {/* <NavLink
        to="/gallery"
        className={({ isActive }) => (isActive ? "underline" : "")}
      >
        <div className="hover:text-slate-500">Gallery</div>
      </NavLink> */}
        <NavLink
          to="/about"
          className={({ isActive }) => (isActive ? "underline" : "")}
        >
          <div className="hover:text-slate-500">About</div>
        </NavLink>
      </div>
      <div className="flex text-xl">
        <NavLink to="/contact">
          <div className="hover:text-white hover:bg-stone-600 rounded-md bg-stone-400 p-3">
            Contact me
          </div>
        </NavLink>
      </div>
    </div>
  );
}

//<div className={isActive ? 'underlined' : 'bold'}>Gallery</div>

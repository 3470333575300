import one from "./1.jpg"
import two from "./2.jpg"
import three from "./3.jpg"
import four from "./4.jpg"
import five from "./5.jpg"

const images = [
    {
        original: one,
        thumbnail: one
    },
    {
        original: two,
        thumbnail: two
    },
    {
        original: three,
        thumbnail: three
    },
    {
        original: four,
        thumbnail: four
    },
    {
        original: five,
        thumbnail: five
    },
]

export default images;
import africa from "../../pics/africa/1.jpg";
import bird from "../../pics/europe-birds/2.jpg";
import bear from "../../pics/bears/1.jpg";
import wildlife from "../../pics/europe-wildlife/1.jpg";
import sandstone from "../../pics/sandstones/1.jpg";
import alps from "../../pics/waterofalps/1.jpg"
import salzburg from "../../pics/salzburg/1.jpg"
import Sidebar from "../../components/Sidebar";
import { NavLink } from "react-router-dom";

export default function Portfolio() {
  return (
    <div className="flex justify-stretch bg-stone-200">
      <div className="w-2/12 bg-stone-300 p-5 rounded text-center h-full">
        <Sidebar />
      </div>
      <div className="w-10/12">
        <div className=" flex items-center bg-stone-300">
          <img className="w-6/12 h-full w-6/12" src={africa} alt="africa" />
          <div id="africa" className="p-10 text-center items-center w-6/12">
            <h2 className="text-5xl pb-10">Eyes of africa</h2>
            <p className="text-2xl font-thin">
              Embark on a visual safari through Botswana's diverse landscapes,
              capturing the majestic elephants, elusive leopards, and
              mesmerizing sunsets, that paint the canvas of this extraordinary
              wildlife haven.
            </p>
            <NavLink to="/portfolio/africa">
              <button className="bg-stone-400 p-3 rounded-md text-black mt-20 text-2xl hover:bg-stone-600 hover:text-white">
                View Gallery
              </button>
            </NavLink>
          </div>
        </div>
        <div className="flex items-center ">
          <div id="birds" className="p-10 text-center w-6/12">
            <h2 className="text-5xl pb-10">European birdlife</h2>
            <p className="text-2xl font-thin">
              Discover the charm and colors of European birds as you browse
              through my collection,where robins, raptors, and songbirds take
              center stage in captivating moments frozen in time.
            </p>
            <NavLink to="/portfolio/europebirds">
              <button className="bg-stone-400 p-3 rounded-md text-black mt-20 text-2xl hover:bg-stone-600 hover:text-white">
                View Gallery
              </button>
            </NavLink>
          </div>
          <img className="h-full w-6/12" src={bird} alt="bird" />
        </div>
        <div className="flex items-center bg-stone-300">
          <img className="h-full w-6/12" src={bear} alt="bear" />
          <div id="alaska" className="p-10 text-center w-6/12">
            <h2 className="text-5xl pb-10">Alaska</h2>
            <p className="text-2xl font-thin">
              Step into the mesmerizing world of Alaska, with its great variety of
              wildlife and gorgeous landscapes.
            </p>
            <NavLink to="/portfolio/alaska">
              <button className="bg-stone-400 p-3 rounded-md text-black mt-20 text-2xl hover:bg-stone-600 hover:text-white">
                View Gallery
              </button>
            </NavLink>
          </div>
        </div>
        <div className="flex items-center ">
          <div id="wildlife" className="p-10 text-center w-6/12">
            <h2 className="text-5xl pb-10">European wildlife</h2>
            <p className="text-2xl font-thin">
              Immerse yourself in the beauty of Europe's diverse
              wildlife through my lens, where each photograph encapsulates the
              untamed essence of the continent's natural wonders.
            </p>
            <NavLink to="/portfolio/wildlife">
              <button className="bg-stone-400 p-3 rounded-md text-black mt-20 text-2xl hover:bg-stone-600 hover:text-white">
                View Gallery
              </button>
            </NavLink>
          </div>
          <img className="h-full w-6/12" src={wildlife} alt="wildlife" />
        </div>
        <div className="flex items-center bg-stone-300">
          <img className="h-full w-6/12" src={sandstone} alt="sandstone" />
          <div id="sandstones" className="p-10 text-center w-6/12">
            <h2 className="text-5xl pb-10">World of sandstones</h2>
            <p className="text-2xl font-thin">
              Discover the intriguing world of sandstones through my
              photographic lens, as I showcase these geological wonders from
              various corners of the globe.
            </p>
            <NavLink to="/portfolio/sandstones">
              <button className="bg-stone-400 p-3 rounded-md text-black mt-20 text-2xl hover:bg-stone-600 hover:text-white">
                View Gallery
              </button>
            </NavLink>
          </div>
        </div>
        <div className="flex items-center ">
          <div id="alps" className="p-10 text-center w-6/12">
            <h2 className="text-5xl pb-10">Waters of the alps</h2>
            <p className="text-2xl font-thin">
              From tranquil valleys to distinct regions, my portfolio
              encapsulates the breathtaking beauty and serenity that define the
              Alpine region.
            </p>
            <NavLink to="/portfolio/watersofalps">
              <button className="bg-stone-400 p-3 rounded-md text-black mt-20 text-2xl hover:bg-stone-600 hover:text-white">
                View Gallery
              </button>
            </NavLink>
          </div>
          <img className="h-full w-6/12" src={alps} alt="alps" />
        </div>
        <div className="flex items-center bg-stone-300">
          <img className="h-full w-6/12" src={salzburg} alt="salzburg" />
          <div id="salzburg" className="p-10 text-center w-6/12">
            <h2 className="text-5xl pb-10">Salzburg nature</h2>
            <p className="text-2xl font-thin">
              Discover the impressive nature of the salzburg county.
            </p>
            <NavLink to="/portfolio/salzburg">
              <button className="bg-stone-400 p-3 rounded-md text-black mt-20 text-2xl hover:bg-stone-600 hover:text-white">
                View Gallery
              </button>
            </NavLink>
          </div>
        </div>
        <p className="bg-stone-300 text-center text-2xl font-thin pb-20 pt-20 italic">More to come soon...</p>
      </div>
    </div>
  );
}

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
//import images from "../../pics/gallery/gallery.js";
import "./Gallery.css";

export default function Gallery() {
  return (
    <div className="bg-stone-200 flex justify-center">
      <ImageGallery
        //items={images}
        showThumbnails={true}
        showFullscreenButton={true}
        showPlayButton={true}
        thumbnailPosition="bottom"
      />
    </div>
  );
}

import papa from "../../pics/papa.png";

export default function About() {
  return (
    <div className="p-10 flex items-center bg-stone-200 h-full">
      <div className="w-6/12">
        <img src={papa} alt="papa" className="w-screen" />
      </div>

      <p className="w-6/12 text-2xl p-20 font-thin">
        Taking pictures is exiting. For thousands of years mankind experienced
        stress and satisfaction in hunting and gathering. When I am out with my
        camera the basic feelings are not so different. If the desired animal
        shows up I may have only a couple of seconds to realize the optimal
        shot, given the light is perfect and the right lens is in place. To
        master the technology of the camera equipment combined with the
        unpredictability of nature is appealing and rewarding. <br/><br/>I live with what
        nature has to offer. I may decide to call it a day and in the last
        minute a leopard shows in the open or all the day long hiking did not
        result in any image that`s worth keeping. But even then it was
        worthwhile to push forward.
      </p>
    </div>
  );
}

import { Link } from "react-scroll";

export default function Sidebar() {
  return (
    
    <ul className="flex-col text-xl sticky top-10">
      <div className="bg-stone-300 p-5 rounded text-center">
        <h1 className="pb-4 text-2xl font-light">Content</h1>
        <li className="cursor-pointer p-2">
          <Link to="africa" spy={true} smooth={true}>
            Eyes of africa
          </Link>
        </li>
        <li className="cursor-pointer p-2">
          <Link to="birds" spy={true} smooth={true}>
            European birdlife
          </Link>
        </li>
        <li className="cursor-pointer p-2">
          <Link to="alaska" spy={true} smooth={true}>
            Alaska
          </Link>
        </li>
        <li className="cursor-pointer p-2">
          <Link to="wildlife" spy={true} smooth={true}>
            European wildlife
          </Link>
        </li>
        <li className="cursor-pointer p-2">
          <Link to="sandstones" spy={true} smooth={true}>
            World of sandstones
          </Link>
        </li>
        <li className="cursor-pointer p-2">
          <Link to="alps" spy={true} smooth={true}>
            Waters of the Alps
          </Link>
        </li>
        <li className="cursor-pointer p-2">
          <Link to="salzburg" spy={true} smooth={true}>
            Salzburg nature
          </Link>
        </li>
      </div>
    </ul>
  );
}


import homePortfolio from "../../pics/home/homePortfolio.png";
import { NavLink } from "react-router-dom";
import FlowingPics from "../../components/FlowingPics";
import images from "../../pics/home/slide";

export default function Home() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="relative bg-slate-200">
        <FlowingPics images={images} />
      </div>
      <div
        id="portfolio"
        className="flex justify-center items-center bg-slate-200"
      >
        <img
          className="w-6/12 h-screen"
          src={homePortfolio}
          alt="homePortfolio"
        />
        <div className="w-6/12 flex flex-col justify-center items-center">
          <h2 className="text-5xl text-center">Portfolio</h2>
          <p className="p-10 text-2xl font-thin text-center">
            If you follow my site over time you`ll realize there is quite some
            energy I put into work in Africa, the southwest of the United States
            and northern Europe. Due to the restrictions to travel in recent
            years I learned about the beauty of my surroundings. There is a
            small swamp not far from the city of Salzburg which I visited a
            couple of times in the last months. In the summer you are able to
            spot birds of prey, different species of waders and the usual pack
            of ducks and geese. Now, in the winter months the mood of the light
            often changes rapidly while it is very quiet – what an experience!
            <br />
          </p>

          <NavLink className="" to="/portfolio">
            <button
              onClick={scrollToTop}
              className="mt-5 text-2xl bg-stone-500 text-white p-3 rounded-md hover:bg-stone-400 hover:text-black"
            >
              View Portfolio
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

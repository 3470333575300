import ImageGallery from 'react-image-gallery';
import images from "../../pics/africa/africa.js";
import "../gallery/Gallery.css";

  export default function Africa  () {

    return (
      <div className='bg-stone-200 flex justify-center'>
        <ImageGallery items={images} 
        showThumbnails={true} 
        showFullscreenButton={true} 
        showPlayButton={true} />
      </div>
    );
    
  };